:root {
    --primary-color: #FC7A57;
    --primary-color-hover: #f39084;
    --primary-color-disabled: #fbd8d4;
    --primary-color-contrast: white;
    --support-color: #eeeeee;
    --border-color: #e9e9e9;
    --support-color-contrast: rgb(14, 14, 14);
    --bg-gradient: linear-gradient(261deg, #ed8074 35%, #f7a95a 100%);
    --bg-gradient-contrast: white;
}