/* You can add global styles to this file, and also import other style files */
@import 'assets/style/colors.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

:focus {outline:0 !important;}

* {
    font-family: 'Noto Sans', sans-serif;
    outline: none !important;
}

body {
    background-color: rgb(255, 255, 255);
    padding: 0;
    margin: 0;
}

.regular-big-svg {
    width: 64px;
    height: 64px;

    & svg {
        width: 64px;
        height: 64px;
    }

    &.white svg{
        fill: white;
    }
}

.regular-svg {
    width: 32px;
    height: 32px;

    & svg {
        width: 32px;
        height: 32px;
    }

    &.white svg{
        fill: white;
    }
}

.regular-md-svg {
    width: 28px;
    height: 28px;

    & svg {
        width: 28px;
        height: 28px;
    }

    &.white svg {
        fill: white;
    }
}


.regular-small-svg {
    width: 24px;
    height: 24px;

    & svg {
        width: 24px;
        height: 24px;
    }

    &.white svg{
        fill: white;
    }
}

.regular-mini-svg {
    width: 19px;
    height: 19px;

    & svg {
        width: 19px;
        height: 19px;
    }

    &.white svg{
        fill: white;
    }
}

.regular-16-svg {
    width: 16px;
    height: 16px;

    & svg {
        width: 16px;
        height: 16px;
    }

    &.white svg{
        fill: white;
    }
}

.regular-14-svg {
    width: 14px;
    height: 14px;

    & svg {
        width: 14px;
        height: 14px;
    }

    &.white svg{
        fill: white;
    }
}

.regular-button {
    border: none;;
    padding: 8px;
    height: 40px;
    background-color: rgb(224, 224, 224);
    border-radius: 4px;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;

    &.green {
        color: white;
        background-color: var(--primary-color);
    
            &:hover {
            background-color: #f7a95a;
            }
    
            &:disabled {
                cursor: not-allowed;
                background-color: #fac590;
            }
    
        }
}

.topnav-container {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;
}

.body-container {
    background-color: #ffffff;
    width: 100%;
    // height: calc(100vh - 72px);
    margin-top: 72px;
    padding: 32px 8px;
    overflow: auto;
    box-sizing: border-box;
}

.policy-wrapper {
    text-align: justify;
    padding: 32px;
}

.map-picker {
    width: 100%;
    height: 450px;
    min-width: 50vw;
    display: block;
}

.form-wrapper {
    margin: 16px;
    display: flex;
    flex-wrap: wrap;
}

.wrapped-section {
    padding: 16px;
}

.field-wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    margin: 0 6px;
    flex: 1 1 0px;
    min-width: 250px;
}

input, textarea {
    border: none;
    padding: 12px 16px;
    height: 50px;
    box-sizing: border-box;
    margin-top: 4px;
    font-size: 15px;
    border-radius: 4px;
    background-color: #f2f2f2;
    resize: none;

    &:disabled {
        cursor: not-allowed;
    }
}

textarea {
    height: auto;
}

select {
    border: none;
    padding: 12px;
    margin-top: 4px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f2f2f2;  
}

.field-error {
    font-size: 11px;
    color: var(--primary-color);
    margin-top: 4px;
}

.field-note {
    font-size: 12px;
    margin-top: 8px;
}

h4 {
    margin: 4px 0;
}

h1 {
    margin-left: 26px;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.fold-image {
    min-width: 400px;
}

.arrow {
    border: solid rgb(104, 104, 104);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }


  /* menu hamburger */

  #menu-clicker
{
  cursor: pointer;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
}


.bar1, .bar2, .bar3, .bar4
{
  width: 36px;
  height: 4px;
  background-color: var(--primary-color);
  margin: 2px 0;
  transition: 0.4s;
}

.bar2
{
  width: 42px;
}
.bar3
{
  width: 42px;
}
.bar4
{
  width: 36px;
}


.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-10px, 6px);
  transform: rotate(-45deg) translate(-10px, 6px);
  width: 40px;

}

.change .bar2, .change .bar3 {opacity: 0;}

.change .bar4 {
    width: 40px;
    -webkit-transform: rotate(45deg) translate(-10px, -7px);
    transform: rotate(45deg) translate(-10px, -7px);

}

.no-results-wrapper {
    font-size: 20px;
    opacity: 0.25;
    padding: 128px 64px;
    text-align: center;
 }

 
.custom-scrollbar {
    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 10px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 12px;
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: black;
    }
  }

  .info-label {
    font-size: 14px;
    color: gray;
    padding: 16px 0;
    display: block;
  }

  .limit-form-wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }